export const NATIVE_DATA_FETCH_NOTIFICATION_SUCCESS =
  'NATIVE_DATA/GET_NOTIFICATION_SUCCESS';
export const NATIVE_DATA_FETCH_SERVICES_STATUS_SUCCESS =
  'NATIVE_DATA/GET_SERVICES_STATUS_SUCCESS';
export const NATIVE_DATA_FETCH_EXPOSURE_NOTIFICATION_STATISTICS_SUCCESS =
  'NATIVE_DATA/GET_EXPOSURE_NOTIFICATION_STATISTICS_SUCCESS';
export const NATIVE_DATA_HIDE_NOTIFICATION_SUCCESS =
  'NATIVE_DATA/HIDE_NOTIFICATION_SUCCESS';
export const NATIVE_DATA_SET_SERVICES_STATUS_SUCCESS =
  'NATIVE_DATA/SET_SERVICES_STATUS_SUCCESS';
export const NATIVE_DATA_SET_SERVICE_STATUS_SOURCE_RESETED =
  'NATIVE_DATA/SET_SERVICE_STATUS_SOURCE_RESETED';
